import { createRouter, createWebHistory } from "vue-router"

const AuthenticationAppIndex = () => import("@/authApp/AuthenticationAppIndex.vue")
const LoginForm = () => import("@/authApp/views/LoginForm.vue")
const PasswordResetAsk = () => import("@/authApp/views/PasswordResetAsk.vue")
const PasswordResetForm = () => import("@/authApp/views/PasswordResetForm.vue")
const ActivationForm = () => import("@/authApp/views/ActivationForm.vue")

export const ROUTE_NAME_LOGIN = "loginForm"
export const ROUTE_NAME_PASSWORD_RESET_ASK = "passwordResetAsk"
export const ROUTE_NAME_PASSWORD_RESET_FORM = "passwordResetForm"
export const ROUTE_NAME_ACTIVATION_FORM = "activationForm"

export const routes = [
    {
        path: "/",
        redirect: { name: ROUTE_NAME_LOGIN }
    },
    {
        path: "/auth",
        component: AuthenticationAppIndex,
        redirect: { name: ROUTE_NAME_LOGIN },
        children: [
            {
                path: "login",
                component: LoginForm,
                name: "loginForm"
            },
            {
                path: "password-reset-ask",
                component: PasswordResetAsk,
                name: ROUTE_NAME_PASSWORD_RESET_ASK
            },
            {
                path: "password-reset-form/:uidb64/:token",
                component: PasswordResetForm,
                name: ROUTE_NAME_PASSWORD_RESET_FORM
            },
            {
                path: "activate/:uidb64/:token",
                component: ActivationForm,
                name: ROUTE_NAME_ACTIVATION_FORM
            }
        ]
    }
]

export const router = createRouter({
    history: createWebHistory(),
    routes
})
