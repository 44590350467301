import { createApp } from "vue"
import { router } from "./router"
import { createPinia } from "pinia"
import AuthenticationAppIndex from "./AuthenticationAppIndex.vue"
import i18n from "@/i18n/i18n"
import vuetify from "@/plugins/vuetify"
import { CONF_FOR_HOST } from "@/i18n/countryConf"
import { SaaSLocale } from "@/models/core/international"
import toolbox from "@/plugins/saasToolboxFrontend"

const pinia = createPinia()

const app = createApp(AuthenticationAppIndex)

const locale: SaaSLocale = CONF_FOR_HOST[window.location.host]?.locale || "fr"
i18n.global.locale = locale

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(vuetify)
app.use(toolbox)
app.mount("#auth-app")
