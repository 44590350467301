import { createVuetify } from "vuetify"
import "vuetify/styles"
import { aliases, mdi } from "vuetify/iconsets/mdi"
import "@mdi/font/css/materialdesignicons.css"
import { VBtn } from "vuetify/lib/components/index.mjs"

const vuetify = createVuetify({
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi
        }
    },
    theme: {
        defaultTheme: "territoriesTheme",
        themes: {
            territoriesTheme: {
                colors: {
                    primary: "#009b81"
                }
            }
        }
    },
    aliases: {
        VBtnPrimary: VBtn,
        VBtnSecondary: VBtn
    },
    defaults: {
        VBtnPrimary: {
            color: "primary",
            variant: "flat"
        },
        VBtnSecondary: {
            color: "primary",
            variant: "outlined"
        },
        VTextField: {
            color: "primary",
            variant: "outlined"
        },
        VTextarea: {
            color: "primary",
            variant: "outlined"
        },
        VSwitch: {
            color: "primary"
        }
    }
})

export default vuetify
